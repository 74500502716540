.splide {
  $root: &;

  &--ttb {
    &.is-active {
      > #{ $root }__slider > #{ $root }__track,
      > #{ $root }__track {
        > #{ $root }__list {
          display: block;
        }
      }
    }
  }
}
