.splide {
  $root: &;

  &--draggable {
    > #{ $root }__slider > #{ $root }__track,
    > #{ $root }__track {
      user-select: none;
    }
  }
}
